.coran-page {
    background: #f6efdf;
    padding: 5px;
    text-align: center;
    height: calc(100vh - 10px);
    position: relative;
    font-size: 12px;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.img-fluid-reverse {
    width: auto;
    max-width: 100%;
    max-height: calc(100% - 70px);
}
.pull-right {
    float: right;
}
.pull-left {
    float: left;
}
.pull-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 30px;
}
.top {
    min-height: 30px;
}

.w-4 {
    width: 33.33%;
    display: inline-block;
}
.w-3 {
    width: 25%;
    display: inline-block;
}

.searchBar {
    padding: 5px;
}
.searchBar input {
    padding: 10px;
    width: calc(100% - 25px);
}
.summary-header {
    display: block;
    text-align: center;
    text-decoration: none;
    color: #333;
    padding: 0 14px;
    margin: 5px;
}
.summary-link {
    display: block;
    text-align: center;
    text-decoration: none;
    color: #333;
    padding: 14px;
    margin: 5px;
    border: 1px solid #777;
}
.summary-link:hover {
    color: #fff;
    background: #777;
}